import React, { useEffect, useState } from 'react';
import { Crisp } from 'crisp-sdk-web';
import { navigate, Link } from 'gatsby';
import PropTypes from 'prop-types';

import logoImg from '../images/logo.png';
import { isNilOrEmpty } from '../utils';
import { getFirebase } from '../utils/signin';
import AuthModal from './AuthModal';
import ContactModal from './ContactModal';
import Footer from './footer';

Crisp.configure(process.env.GATSBY_CRISP_WEBSITE_ID, { autoload: false });

function Layout({ noHeader, children }) {
    const [isOpenAuthModal, setIsOpenAuthModal] = useState(false);
    const [isSignedIn, setIsSignedIn] = useState(false);
    const firebase = getFirebase();
    useEffect(() => {
        Crisp.load();
        if (isNilOrEmpty(firebase)) return;

        const unregisterAuthObserver = getFirebase()
            .auth()
            .onAuthStateChanged((user) => {
                setIsSignedIn(!!user);
            });
        return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }, []);

    const handlerRedirectToMyMenus = () => {
        navigate('/app');
    };
    const signInSuccessHandler = (authResult, redirectUrl) => {
        handlerRedirectToMyMenus();
    };
    const handleOpenAuthModal = () => {
        if (isSignedIn) {
            handlerRedirectToMyMenus();
            return;
        }

        setIsOpenAuthModal(true);
    };

    return (
        <body className="leading-normal tracking-normal">
            <div className="text-white gradient">
                {/* Navigation */}
                {noHeader ? null : (
                    <nav id="header" className="fixed w-full z-30 top-0 text-white bg-white shadow">
                        <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
                            <div className="pl-4 flex items-center">
                                <a
                                    className="toggleColour no-underline hover:no-underline font-bold text-xl lg:text-4xl text-gray-800"
                                    href="/"
                                >
                                    <img className="h-16 fill-current inline" src={logoImg} />
                                    eMenila
                                </a>
                            </div>

                            <div className="block lg:hidden pr-4">
                                <button
                                    id="nav-toggle"
                                    className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 lg:bg-transparent text-black p-4 lg:p-0 z-20 bg-white"
                                >
                                    <svg
                                        className="fill-current h-3 w-3"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <title>Menu</title>
                                        <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
                                    </svg>
                                </button>
                            </div>
                            <Link
                                to="/pricing"
                                className="block font-bold md:inline-block lg:hidden mr-6 no-underline text-black"
                            >
                                Nos offres
                            </Link>
                            <div
                                className="w-full flex-grow lg:flex lg:items-center lg:w-auto hidden lg:block mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20"
                                id="nav-content"
                            >
                                <ul className="list-reset lg:flex justify-end flex-1 items-center">
                                    <Link
                                        to="/blog"
                                        className="block md:inline-block mt-4 md:mt-0 no-underline text-black"
                                    >
                                        Blog
                                    </Link>
                                </ul>
                                <ul className="list-reset lg:flex justify-end items-center">
                                    <Link
                                        to="/pricing"
                                        className="block md:inline-block mt-4 ml-4 md:mt-0 no-underline text-black"
                                    >
                                        Nos offres
                                    </Link>
                                </ul>
                                <ul className="list-reset lg:flex justify-end items-center">
                                    <a
                                        onClick={handleOpenAuthModal}
                                        className="block md:inline-block mt-4 md:mt-0 ml-4 font-semibold mr-4 no-underline text-sapin-700 cursor-pointer"
                                    >
                                        Mon espace pro
                                    </a>
                                </ul>
                                <button
                                    onClick={handleOpenAuthModal}
                                    type="submit"
                                    id="navAction"
                                    className="mx-auto lg:mx-0 hover:underline font-bold rounded-full mt-4 lg:mt-0 py-4 px-8 shadow bg-black text-white text-black border border-black "
                                >
                                    Essayer gratuitement
                                </button>
                            </div>
                        </div>

                        <hr className="border-b border-gray-100 opacity-25 my-0 py-0" />
                    </nav>
                )}
                <AuthModal
                    signInSuccessHandler={signInSuccessHandler}
                    isOpen={isOpenAuthModal}
                    setIsOpen={setIsOpenAuthModal}
                />

                {children}

                {/* Footer */}
                <Footer />
            </div>
        </body>
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;

import React from 'react';
import classNames from 'classnames';

const Loader = ({ size = 'lg', disableAbsolute = false }) => (
    <div
        className={classNames('flex bg-white opacity-75 z-50 items-center justify-center', {
            'w-full h-full': size === 'lg',
            'absolute top-0 left-0 bottom-0': !disableAbsolute
        })}
    >
        <svg
            className={classNames('animate-spin ml-1 mr-3 text-sapin-400', {
                'h-28 w-28': size === 'lg',
                'h-14 w-14': size === 'md',
                'h-6 w-6': size === 'sm'
            })}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
        >
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth={4} />
            <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
        </svg>
    </div>
);

export default Loader;
